import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import Col from 'reactstrap/lib/Col';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';
import Row from 'reactstrap/lib/Row';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';

import { Ticket } from '@ttstr/api/products';
import { Container, ProductDetailHeader, ProductInfoBar, Loading, VariantListing, TicketMarkup } from '@ttstr/components';
import FormField from '@ttstr/components/Form/FormField';
import MerchantInfo from '@ttstr/components/ProductDetail/MerchantInfo';

const SeatingPlan = React.lazy(() => import('@ttstr/components/ProductDetail/SeatingPlan'));

interface OwnProps {
  readonly product: Ticket;
}

type Props = Readonly<OwnProps>;

const TicketDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState('best-place');

  return (
    <article className="ticket-detail" itemScope itemType="http://schema.org/Event">
      <Helmet>
        <body className="ticket-detail-page" />
      </Helmet>
      <TicketMarkup ticket={product} />

      <ProductDetailHeader
        className="product-header tt-sticky-top"
        background={product.hero_image.url}
        image={product.hero_image.url}
      />

      <div className="product-content">
        <Card body inverse color="primary">
          <Container className="text-center">
            {product.supertitle && <span className="text-muted">{product.supertitle}</span>}
            <h1 className="display-5 my-0">
              <span itemProp="name">{product.title}</span>
              <small>{product.subtitle}</small>
            </h1>
          </Container>
        </Card>
        <ProductInfoBar product={product} />

        <Container className="detail-page my-5">
          <header className="text-center">
            {/* {product.supertitle && <span className="text-muted">{product.supertitle}</span>} */}
            {/* <h1 className="display-5 my-3">
              <span itemProp="name">{product.title}</span>
              <small>{product.subtitle}</small>
            </h1> */}
          </header>

          {product.has_plan && (
            <div className="mb-3 radio-list-group">
              <Row noGutters>
                <Col className="d-flex align-items-stretch">
                  <ListGroupItem
                    className="text-center d-flex flex-column justify-content-between"
                    color="secondary"
                    tag="label"
                    active={activeTab === 'best-place'}
                    action
                  >
                    <h2 className="h5">{t('PRODUCT.BEST_PLACE_BOOKING')}</h2>
                    <p>{t('PRODUCT.BEST_PLACE_BOOKING_DESCRIPTION')}</p>
                    <FormField
                      id="activetab-best-place"
                      type="radio"
                      value="best-place"
                      checked={activeTab === 'best-place'}
                      onChange={(e) => setActiveTab(e.target.value)}
                    />
                  </ListGroupItem>
                </Col>
                <Col className="d-flex align-items-stretch">
                  <ListGroupItem
                    className="text-center d-flex flex-column justify-content-between"
                    color="secondary"
                    tag="label"
                    active={activeTab === 'seatingplan'}
                    action
                  >
                    <h2 className="h5">{t('PRODUCT.SEATINGPLAN_BOOKING')}</h2>
                    <p>{t('PRODUCT.SEATINGPLAN_BOOKING_DESCRIPTION')}</p>
                    <FormField
                      id="activetab-seatingplan"
                      type="radio"
                      value="seatingplan"
                      checked={activeTab === 'seatingplan'}
                      onChange={(e) => setActiveTab(e.target.value)}
                    />
                  </ListGroupItem>
                </Col>
              </Row>
            </div>
          )}
          <TabContent activeTab={activeTab}>
            <TabPane tabId="best-place">
              <Row>
                <Col xl className="order-xl-last">
                  <div className="product-info sticky-top">
                    <h2 className="product-title">Bitte Ticket wählen</h2>
                    {/* <VariantChooser product={product} /> */}
                    {(product.allow_bestplace !== null) && product.allow_bestplace === false ? (
                      <>
                        <p className="bestplace_not_available-description">{t('PRODUCT.BEST_PLACE_BOOKING_NOT_AVAILABLE')}</p>
                        <Button className="bestplace_not_available-button" onClick={() => setActiveTab('seatingplan')}>
                          {t('PRODUCT.GOTO_SEATINGPLAN_BOOKING')}
                        </Button>
                      </>
                    ) : (
                      <VariantListing product={product} />
                    )}
                  </div>
                </Col>
                {product.description && (
                  <Col xl className="order-xl-first">
                    <h2 className="product-title">{t(`PRODUCT.INFORMATION`)}</h2>
                    <div dangerouslySetInnerHTML={{ __html: product.description }} itemProp="description" />
                  </Col>
                )}
                {/* {product.merchant_id && <MerchantInfo merchantId={product.merchant_id} />} */}
              </Row>
            </TabPane>
            <TabPane tabId="seatingplan">
              <Loading>
                {activeTab === 'seatingplan' && product.has_plan && (
                  <SeatingPlan
                    product={product}
                    variants={
                      Array.isArray(product.online_variants_attributes)
                        ? product.online_variants_attributes
                        : Object.values(product.online_variants_attributes)
                    }
                  />
                )}
              </Loading>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </article>
  );
};

export default React.memo(TicketDetail);
