import queryString from 'querystring';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink as RouterNavLink, useHistory, useLocation, matchPath } from 'react-router-dom';

import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import Nav from 'reactstrap/lib/Nav';
import Navbar from 'reactstrap/lib/Navbar';
import NavbarText from 'reactstrap/lib/NavbarText';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
// @ts-ignore
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';

import { Category } from '@ttstr/api';
import { Navigation, useAuth, useProductDetails } from '@ttstr/components';
import MenuPortal from '@ttstr/components/Navigation/MenuPortal';
import UserLoginModal from '@ttstr/components/Navigation/UserLoginModal';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { categories, categoriesReceived } = useProductDetails();
  const { loggedIn, showLogin, setShowLogin, redirectAfterLogin } = useAuth();
  const [mainMenuRef, setMainMenuRef] = React.useState<HTMLDivElement>(); // Could be put into Context
  const [customerMenuRef, setCustomerMenuRef] = React.useState<HTMLLIElement>(); // Could be put into Context
  const [navbarRef, setNavbarRef] = React.useState<HTMLUListElement>(); // Could be put into Context

  const qs = React.useMemo(() => {
    return queryString.parse(location.search.substring(1));
  }, [location.search]);
  const selectedCategoryId = qs.categoryId;

  const toggleUserLoginModal = () => {
    setShowLogin(!showLogin);
  };

  const isRoot = React.useMemo(() => {
    const matches = matchPath(location.pathname, { path: '/', exact: true });
    return matches && matches.isExact;
  }, [location.pathname]);

  return (
    <header id="header" className="d-print-none">
      <Navigation
        logo={require('./assets/images/zwoelfk_logo_w.svg')}
        fixed="top"
        dark
        color="dark"
        cartIcon={<i className="fal fa-shopping-cart" />}
        mainMenuRef={setMainMenuRef}
        customerMenuRef={setCustomerMenuRef}
        navbarRef={setNavbarRef}
        showRegister={false}
        alwaysCloseMainMenu
        className="main-navigation"
      />

      <div className="below-main-navigation container-fluid text-white text-center bg-info py-3">
        {/* eslint-disable react/jsx-no-literals */}
        <strong>Versandkostenfrei</strong> in DE ab <strong>100€ Warenwert</strong>
        {/* eslint-enable react/jsx-no-literals */}
      </div>

      <MenuPortal target={navbarRef}>
        <NavItem>
          <a
            href="https://www.instagram.com/12k.official/"
            rel="noreferrer noopener"
            target="_blank"
            className="nav-link"
          >
            <i className="fab fa-instagram" />
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <span className="sr-only">instagram</span>
          </a>
        </NavItem>
      </MenuPortal>

      <MenuPortal target={mainMenuRef}>
        <Nav navbar>
          <NavItem>
            <NavLink tag={RouterNavLink} to="/products" exact className="nav-link">
              {t(`NAVIGATION.ALL`)}
            </NavLink>
          </NavItem>
          {categoriesReceived &&
            Object.values(categories)
              .sort((a: Category, b: Category) => a.position - b.position)
              .map((c: Category) => (
                <NavItem key={c.id}>
                  <NavLink
                    tag={RouterNavLink}
                    to={`/products?categoryId=${c.id}#product-listing`}
                    replace={isRoot}
                    isActive={() => selectedCategoryId === String(c.id)}
                    className="nav-link"
                  >
                    {c.title}
                  </NavLink>
                </NavItem>
              ))}
        </Nav>
      </MenuPortal>

      <MenuPortal target={customerMenuRef}>
        {loggedIn ? (
          <UncontrolledDropdown>
            <DropdownToggle nav caret title={t(`NAVIGATION.ACCOUNT`)} className="text-info">
              <i className="fal fa-user" /> <span className="sr-only">{t(`NAVIGATION.ACCOUNT`)}</span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag={NavLink} to="/customer/account" exact>
                {t(`CUSTOMER.TITLE`)}
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/customer/orders" exact>
                {t(`ORDERS.TITLE`)}
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/customer/landing-page" exact>
                {t(`CUSTOMER.LANDING_PAGE.TITLE`)}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag={NavLink} to="/customer/logout">
                {t('CUSTOMER.LOGOUT')}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : (
          <NavItem tag="div">
            <NavLink
              tag={RouterNavLink}
              to="#"
              isActive={() => false}
              onClick={(e) => {
                e.preventDefault();
                toggleUserLoginModal();
              }}
              className="nav-link"
              title={t(`NAVIGATION.ACCOUNT`)}
            >
              <i className="fal fa-user" /> <span className="sr-only">{t(`NAVIGATION.ACCOUNT`)}</span>
            </NavLink>
          </NavItem>
        )}
        <UserLoginModal
          show={showLogin}
          toggle={toggleUserLoginModal}
          onLoginSuccess={() =>
            redirectAfterLogin && history.push(typeof qs.success === 'string' ? qs.success : '/customer/landing-page')
          }
        >
          <div className="alert alert-default p-0">{t(`LOGIN.INTRO`)}</div>
        </UserLoginModal>
      </MenuPortal>
    </header>
  );
};

export default React.memo(Header);
