import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Container from 'reactstrap/lib/Container';

import { ContentrArea } from '@ttstr/components';

const Imprint: React.FC = () => {
  const { t } = useTranslation();

  return (
    <article className="my-5">
      <Container>
        <Helmet>
          <title>Versand &amp; Rückgabe</title>
        </Helmet>
        <ContentrArea id="shipping-and-refund-main" />
      </Container>
    </article>
  );
};

export default React.memo(Imprint);
