// /* IE9, IE10 and IE11 requires all of the following polyfills. */
import 'core-js/features/array';
import 'core-js/features/date';
import 'core-js/features/function';
import 'core-js/features/map';
import 'core-js/features/math';
import 'core-js/features/number';
import 'core-js/features/object';
import 'core-js/features/parse-float';
import 'core-js/features/parse-int';
import 'core-js/features/regexp';
import 'core-js/features/set';
import 'core-js/features/string';
import 'core-js/features/symbol';
import 'core-js/features/weak-map';

import 'abort-controller/polyfill';
import 'whatwg-fetch';

// @ts-ignore
// [
//   'article',
//   'aside',
//   'dialog',
//   'figcaption',
//   'figure',
//   'footer',
//   'header',
//   'hgroup',
//   'main',
//   'nav',
//   'section',
//   'output',
// ].forEach((tag) => document.createElement(tag));

// @see https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
if (window.Element && !Element.prototype.closest) {
  Element.prototype.closest = function (s: string) {
    const matches = ((this as any).document || this.ownerDocument).querySelectorAll(s);
    let i;
    let el = this;
    do {
      i = matches.length;
      while (--i >= 0 && matches.item(i) !== el) {}
    } while (i < 0 && (el = el.parentElement));
    return el;
  };
}
